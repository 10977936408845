angular.module('RocketWash').controller('WashChartsRevenueController', (
  $scope, $q, $rootScope, $http, $translate, $timeout, userSession, MarketingActivity,
) => {
  $rootScope.loading = 'income';
  $scope.group.measure_time = 'paid_at';
  $scope.group.shift_type = 'full_day';
  $scope.group.service_type = 'all';

  const bandsEvents = {
    mouseover: function (_event) {
      this.label.element.classList.add('label-visible');
    },
    mouseout: function (_event) {
      this.label.element.classList.remove('label-visible');
    },
  };

  const activitiesToBands = (marketingActivities) => {
    const n = marketingActivities.length;
    if (n === 0) {
      return [];
    }
    const shift = 180 - 180 / n;
    return _.sortBy(marketingActivities, 'dateStart').map((ma, i) => {
      return {
        from: +moment(ma.dateStart),
        to: +moment(ma.dateEnd),
        color: `hsla(${Math.round((shift * i) % 360)}, 100%, 50%, 0.1)`,
        label: {
          text: ma.name,
          // y: 15 + (i % 2) * 10,
        },
        events: bandsEvents,
      };
    });
  };

  $scope.updateChartData = (drawOptions) => {
    const chartPromise = $http({
      url: '/wash/charts/income',
      method: 'GET',
      params: $scope.group,
    }).then((response) => {
      const series = response.data.filter(x => x.name !== 'internal_resources')
      $scope.series = [
        series[1],
        series[6],
        series[2],
        series[4],
        series[0],
        series[3],
        series[5],
      ];
    });

    const maPromise = MarketingActivity.queryAll().then((response) => {
      $scope.marketingActivities = response;
      $scope.bands = activitiesToBands($scope.marketingActivities);
    });

    const allPromise = $q.all([chartPromise, maPromise]);
    allPromise.then(() => {
      $scope.updateChart(drawOptions);
      if ($rootScope.loading === 'income') {
        $rootScope.loading = false;
      }
    });
  };
  $scope.updateChart = (drawOptions) => {
    $scope.series.forEach((serie) => {
      const name = serie.name;
      serie.data = serie.data.filter(item => item[0]);
      serie.data.forEach((item) => { // TODO: replace with map
        item[0] = +moment(item[0]);
        item[1] = parseFloat(item[1]);
      });
      angular.extend(serie, {
        name: $translate.instant(`charts.income.serie_label.${name}`),
        tooltip: {
          valueSuffix: $translate.instant('charts.income.tooltip.valueSuffix.profit'),
        },
        color: $translate.instant(`charts.income.color.${name}`),
        data: serie.data.slice().sort((x, y) => x[0] - y[0]),
      });
    });
    $scope.rangeMin = 0;
    $scope.rangeMax = +moment();
    if ($scope.chart) {
      $scope.chart.destroy();
    }
    const update = () => {
      $scope.chart = new Highcharts.StockChart({
        title: {
          text: $translate.instant('charts.income.title'),
        },
        subtitle: {
          text: null,
        },
        rangeSelector: {
          selected: 0,
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: $translate.instant('charts.income.labels.date'),
            style: '',
          },
          events: {
            setExtremes: (e) => {
              $scope.$apply(() => {
                $scope.rangeMin = e.min;
                $scope.rangeMax = e.max;
              });
            },
          },
          plotBands: $scope.bands,
        },
        yAxis: {
          title: {
            text: userSession.service_location.currency,
            style: '',
          },
          labels: {
            plotLines: [
              {
                value: 0,
                width: 2,
                color: 'silver',
              },
            ],
          },
        },
        plotOptions: {
          // turboThreshold: 10000,
          series: {
            stacking: 'normal',
            marker: {
              enabled: false,
            },
          },
        },
        series: $scope.series,
        legend: {
          borderRadius: 2,
          borderColor: '#c1c1c1',
          backgroundColor: '#f9f9f9',
        },
        chart: {
          renderTo: 'charts-container-income',
          borderRadius: 3,
          showAxes: true,
          borderColor: '#c4c4c4',
          reflow: false,
          backgroundColor: '#ffffff',
          spacing: '10,10,15,10',
          type: 'area',
          plotBackgroundImage: null,
          spacingLeft: 8,
          spacingBottom: 8,
          spacingRight: 8,
          plotBorderWidth: 6,
          borderWidth: 1,
          selectionMarkerFill: 'rgb(137,210,255)',
          zoomType: 'x',
          plotBorderColor: '#ffffff',
          plotBackgroundColor: '#fcfcfc',
          spacingTop: 8,
          height: 550,
        },
        exporting: {
          scale: 1,
          enabled: false,
          sourceHeight: 10,
          sourceWidth: 10,
        },
        tooltip: {
          borderColor: '#ffffff',
          backgroundColor: 'rgba(255, 255, 255, 0.85)',
          headerFormat: `
            <span style="font-size: 1.0em">{this.total}</span>
            <br/>
            <span style="font-size: 1.0em">{point.key}</span>
            <br/>
          `,
          formatter() {
            let result = `<span> ${moment(this.points[0].key).format('MMMM Do YYYY')}</span> <br/>`;
            this.points = this.points.filter(point => point.point.y != 0)
            if (this.points.length > 0) {
              result += this.points.map(point => `
              <span style="color: ${point.series.color}">${point.series.name}</span>:
              <b>${point.point.y || 0} ${userSession.service_location.currency}</b>
              <br/>`).join(' ');
              result += `
              <span>${$translate.instant('charts.income.total')}</span>
              :
              <b>${this.points[0].total || 0} ${userSession.service_location.currency}</b>
              <br/>`;
            }
            return result;
          },
        },
      });
    };
    $timeout(update);
  };

  $scope.$watch('group', $scope.updateChartData, true);
  $scope.$on('left-menu-state-changed', () => {
    $timeout(() => {
      if (!$scope.chart) {
        return;
      }
      $scope.chart.reflow();
    });
  });
});
