angular.module('RocketWash').controller('WashSettingsCarTypesGroupsBaseController', (
  $scope, $uibModal, CarTypesGroup, rwConfirmationService, ServiceLocation, ctgSelectService
) => {

  $scope.tabs = [
    {
      label: 'Внутренниие группы',
      value: false,
    },
    {
      label: 'Группы такси',
      value: true,
    }
  ];

  $scope.reloadAll = () => {
    return CarTypesGroup.all({}, { cache: false }).then((carTypesGroups) => {
      $scope.carTypesGroups = carTypesGroups;
      $scope.selected.ctg = $scope.carTypesGroups[0];
      $scope.reloadCarTypes();
    });
  };

  ServiceLocation.query().then((sls) => {
    $scope.serviceLocations = sls;
  });

  $scope.delete = (carType) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => {
        carType.delete().then(() => {
          ctgSelectService.reload();
          $scope.reloadAll();
        });
      },
    );
  };

  $scope.deleteCtg = (ctg) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.delete_car_types_group' },
      () => {
        ctg.delete().then(() => {
          ctgSelectService.reload();
          $scope.reloadAll();
        });
      },
    );
  };


  $scope.$on('search-change', (_event, data) => {
    $scope.searchQuery = data.value;
  });

  $scope.openFormPopup = (carType) => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/car_types_groups/form.slim',
      controller: 'WashSettingsCarTypesGroupsFormController',
      resolve: {
        resolvedCarTypesGroup: () => carType,
      },
    }).result.finally(() => {
      setTimeout(() =>{
        $scope.reloadAll();
        ctgSelectService.reload();
      }, 1000);
    });
  };

  $scope.save = (carType) => {
    carType.save().then(() => {
      $scope.reloadAll();
      ctgSelectService.reload();
    });
  };

  $scope.reloadAll();
});
