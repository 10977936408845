angular.module('RocketWash').controller('AdminVehiclesController', function($scope, CarMake, CarModel, CarType) {
  var reloadCarMakes;
  CarType.query().then(function(carTypes) {
    return $scope.carTypes = carTypes;
  });
  $scope.selected = {
    type: 'all'
  };
  $scope.searchQuery = '';
  $scope.predicate = 'name';
  $scope.reverse = true;
  $scope.carTypeName = function(model) {
    var carType;
    carType = _.find($scope.carTypes, function(ct) {
      return parseInt(ct.id) === parseInt(model.defaultCarTypeId);
    });
    return carType != null ? carType.name : void 0;
  };
  $scope.changeSelected = function(type) {
    var i, len, model, ref;
    ref = $scope.selectedModels();
    for (i = 0, len = ref.length; i < len; i++) {
      model = ref[i];
      model.defaultCarTypeId = type.id;
      model.save();
    }
    return reloadCarMakes($scope.selected.type.id);
  };
  $scope.selectedModels = function() {
    var i, j, len, len1, make, model, models, ref, ref1;
    models = [];
    if ($scope.carMakes) {
      ref = $scope.carMakes;
      for (i = 0, len = ref.length; i < len; i++) {
        make = ref[i];
        if (make.carModels) {
          ref1 = make.carModels;
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            model = ref1[j];
            if (model.selected) {
              models.push(model);
            }
          }
        }
      }
    }
    return models;
  };
  $scope.selectMake = function(make) {
    var i, len, model, ref, results;
    if (make.carModels) {
      ref = make.carModels;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        model = ref[i];
        results.push(model.selected = make.selected);
      }
      return results;
    } else {
      return $scope.loadMake(make, function() {
        var j, len1, ref1, results1;
        ref1 = make.carModels;
        results1 = [];
        for (j = 0, len1 = ref1.length; j < len1; j++) {
          model = ref1[j];
          results1.push(model.selected = make.selected);
        }
        return results1;
      });
    }
  };
  $scope.addCar = function(carMake) {
    var newModel;
    newModel = new CarModel({
      carMakeId: carMake.id,
      defaultCarTypeId: $scope.selected.type.id
    });
    if (carMake.carModels != null) {
      return carMake.carModels.unshift(newModel);
    } else {
      return $scope.loadMake(carMake, function() {
        carMake.carModels || (carMake.carModels = []);
        return carMake.carModels.unshift(newModel);
      });
    }
  };
  $scope.sortBy = function(predicate) {
    $scope.predicate = predicate;
    return $scope.reverse = !$scope.reverse;
  };
  $scope.$watch('selected.type', function(newType) {
    if (newType === 'undefined') {
      return reloadCarMakes('undefined');
    } else if (newType === 'all') {
      return reloadCarMakes();
    } else {
      return reloadCarMakes(newType != null ? newType.id : void 0);
    }
  });
  reloadCarMakes = function(carTypeId) {
    return CarMake.query({
      car_type_id: carTypeId
    }, {
      cache: false
    }).then(function(data) {
      data = _.map(data, function(o) {
        return new CarMake(o);
      });
      return $scope.carMakes = _.sortBy(data, 'name');
    });
  };
  $scope.save = function(model) {
    return model.save();
  };
  $scope.saveMake = function(make) {
    return make.save();
  };
  $scope.addMake = function() {
    var make;
    make = new CarMake({
      type: $scope.selected.type
    });
    return $scope.carMakes.unshift(make);
  };
  $scope["delete"] = function(make, model) {
    if (confirm($scope.$translate.instant('alert.are_you_sure'))) {
      return model["delete"]().then(function() {
        return make.carModels = _.without(make.carModels, model);
      });
    }
  };
  $scope.openMake = function(make) {
    if ($scope.selectedMake === make) {
      return $scope.selectedMake = null;
    } else {
      $scope.selectedMake = make;
      return $scope.loadMake(make);
    }
  };
  return $scope.loadMake = function(make, callback) {
    if (!make.carModels) {
      return CarMake.get(make.id).then(function(data) {
        data.carModels = _.map(data.carModels, function(cm) {
          return new CarModel(cm);
        });
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(make, data);
        return typeof callback === "function" ? callback() : void 0;
      });
    }
  };
});
