import { env } from '@env';

angular.module('RocketWash').controller('WashChartsReconciliationReportController', (
  $scope, $uibModal, Customer, FinancialOperation, FinancialCenter,
) => {
  $scope.selected = {
    type: 'Company'
  };

  $scope.types = [
    {name: 'All', value: undefined},
    {name: 'Individual', value: 'Individual'},
    {name: 'Entrepreneur', value: 'Entrepreneur'},
    {name: 'Company', value: 'Company'},
  ]

  $scope.recieptAvailable = ['Company', 'Entrepreneur'].includes($scope.selected.type);

  $scope.pds = $scope.pageDataService.new({
    page: 'reconciliation_report',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
      ],
      scopes: ['name'],
    },
    pg: {
      url: '/wash/charts/reconciliation_report',
      model: Customer,
      query: {
        skipDateFilter: true,
        predicate: 'name',
        type: 'Company',
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.updateTable = (field) => {
    $scope.pds.pg.query.type = $scope.selected.type;
    $scope.$broadcast('pds-update');
  };

  $scope.downloadReconciliationReport = (customer) => {
    window.open(`${env.apiUrl}/wash/reconciliation_report/download_for_customer.xlsx?user_id=${customer.id}&${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.downloadOrganizationReport = (customer) => {
    window.open(`${env.apiUrl}/wash/reconciliation_report/download_organization_reciept.xlsx?user_id=${customer.id}&${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.downloadCompletedServicesReport = (customer) => {
    window.open(`${env.apiUrl}/wash/reconciliation_report/download_completed_services_report.xlsx?user_id=${customer.id}&${$.param($scope.pds.pg.query)}`, '_blank');
  }
});
