

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('CarMake', function($http, railsResourceFactory, userSession) {
  var CarMake, CarMakeFactory;
  CarMakeFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/car_makes/" + (context.id || '') },
    name: "car_make"
  });
  return CarMake = (function(superClass) {
    extend(CarMake, superClass);

    function CarMake() {
      return CarMake.__super__.constructor.apply(this, arguments);
    }

    CarMake.query = function(queryParams, context) {
      var cache;
      cache = context && context.cache !== false;
      return $http({
        url: this.resourceUrl(context),
        params: queryParams,
        method: 'GET',
        cache: cache
      }).then(function(response) {
        return _.sortBy(response.data, 'name');
      });
    };

    return CarMake;

  })(CarMakeFactory);
});
