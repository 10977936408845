import { env } from '@env';

angular.module('RocketWash').controller('WashCounterpartiesEditController', (
  $scope, $filter, viewStore, serverConstants, User, Tag, userSession, SettingValue, alertService, CarTypesGroup,
) => {
  $scope.env = env;
  $scope.contractor = new User(viewStore.data.contractor);
  if ($scope.contractor.id) {
    $scope.contractor.get().then(() => {
      $scope.contractor.dateOfBirthFormatted = $scope.contractor.dateOfBirth;
    });
  } else {
    $scope.contractor.tags = [];
  }
  if (!$scope.contractor) {
    $scope.$dismiss();
    return;
  }

  $scope.tabs = ['info', 'vehicles', 'history', 'financial_operations', 'reviews'];
  if ($scope.contractor.id && userSession.planSettings().personal_client_cabinet) {
    $scope.tabs.push('cabinet');
  };

  $scope.selected = { tab: $scope.tabs[0] };
  $scope.sex = serverConstants.user_sexes;
  $scope.types = serverConstants.user_types;
  $scope.userSession = userSession;

  $scope.delete = () => {
    $scope.contractor.delete().then(() => {
      $scope.$dismiss();
    });
  };

  $scope.saveAndContinue = () => {
    $scope.errors = { userInfo: {} };
    ($scope.contractor.cars || []).forEach((car) => {
      car.carMakeId = car.carMake && car.carMake.id;
      car.carModelId = car.carModel && car.carModel.id;
    });
    $scope.contractor.tagIds = ($scope.contractor.tags || []).map(x => x.id);
    $scope.contractor.carTypesGroupIds = ($scope.contractor.carTypesGroups || []).map(x => x.id);
    $scope.contractor.bonusesPercentage =
      parseInt($scope.contractor.bonusesPercentage, 10) || 0;
    if ($scope.contractor.dateOfBirthFormatted) {
      const dob = moment($scope.contractor.dateOfBirthFormatted, 'DD/MM/YYYY');
      if (!moment(dob, 'DD/MM/YYYY').isValid()) {
        $scope.errors = { userInfo: { dateFormat: true } };
        return;
      }
      $scope.contractor.dateOfBirth = dob.format('YYYY-MM-DD');
    }
    $scope.saveDisabled = true;
    return $scope.contractor.save().then((data) => {
      $scope.errors.userInfo = data.errors;
      removeErrorDuplicates("cars.contractor.", $scope.errors.userInfo);

      $scope.contractor.dateOfBirthFormatted = $scope.contractor.dateOfBirth;

      if (_.isEmpty($scope.errors.userInfo)) {
        alertService.show({
          text: 'Клиент успешно сохранен',
        });
      }

      return data;
    }).finally((data) => {
      $scope.saveDisabled = false;

      return data;
    });
  };

  $scope.save = () => {
    $scope.saveAndContinue().then((data) => {
      if (_.isEmpty($scope.errors.userInfo)) {
        $scope.$dismiss();
      }
    });
  };

  $scope.typeaheadTags = (searchQuery) => {
    return Tag.typeahead(searchQuery);
  };
  $scope.onTagAdding = (tag) => {
    if (tag.id) {
      return true;
    }
    return new Tag(tag).save().then((response) => {
      if (!response.id) {
        throw new Error('failed to create tag');
      }
      tag.id = response.id;
    });
  };

  const removeErrorDuplicates = (patternKey, errors) => {
    const keys = Object.keys(errors);

    _.forEach(keys, (k) => {
      if (k.includes(patternKey)) {
        delete errors[k];
      }
    })
  }

  SettingValue.query().then((settingValues) => {
    let setting = settingValues.find(x => x.key == 'use_debt_payments');
    $scope.debtPaymentsEnabled = setting.value;
  });

  CarTypesGroup.all().then((ctgs) => {
    $scope.availableCtgs = ctgs.filter(ctg => !ctg.taxi);
  });

  $scope.searchCtgs = (text) => {
    return $filter('filter')($scope.availableCtgs, text);
  };
});
