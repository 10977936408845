angular.module('RocketWash').controller('WashIncomeMotivationPercentageController', (
  $q, $scope, $uibModal, Job, BasicService, JobOptionsForService, ctgSelectService
) => {
  $scope.active = { jobs: [] };

  $scope.recalculateGroupedJOFS = () => {
    let grouped = {};

    $scope.services.forEach((service) => {
      grouped[service.id] = {};
      $scope.jobs.forEach((job) => {
        grouped[service.id][job.id] = new JobOptionsForService({ jobId: job.id, serviceId: service.id });
      });
    });

    $scope.jofs.forEach((item) => {
      grouped[item.serviceId] = grouped[item.serviceId] || {};
      grouped[item.serviceId][item.jobId] = item;
    });

    $scope.jobOptionsForServices = grouped;
  };

  let jobsPromise = Job.query().then((jobs) => {
    $scope.jobs = _.sortBy(jobs, 'name');
    $scope.active.jobs.push($scope.jobs[0]);
  });

  $scope.reload = () => {
    if (ctgSelectService.selected) {
      let basicServicesPromise = BasicService.indexSimple({
        car_types_group_id: ctgSelectService.selected.id
      }).then((data) => {
        $scope.services = data.map(x => new BasicService(x));
        $scope.serviceCategories = $scope.getServiceCategories($scope.services);
      });

      JobOptionsForService.query().then((data) => {
        $scope.jofs = data.map(x => new JobOptionsForService(x));

        $q.all([jobsPromise, basicServicesPromise]).then(() => {
          $scope.recalculateGroupedJOFS();
        })
      });
    }
  };

  $scope.updateActiveJobs = () => {
    $scope.jobs.forEach((job) => {
      job.active = $scope.active.jobs.indexOf(job) >= 0;
    });
  };

  $scope.reload();
  $scope.$on('ctgSelectService-selected', () => {
    $scope.reload();
  });


  $scope.getServiceCategories = (services) => {
    const groups = _.groupBy(services, service => service.category || '');
    const groupsArray =
      Object.entries(groups).map(([groupName, groupServices]) => [
      groupName,
      _.sortBy(groupServices, (s) => {
        return s.name && s.name.toLowerCase();
      }),
    ]);
    return _.sortBy(groupsArray, g => g[0].toLowerCase());
  };

  $scope.saveJobOptionsForService = (jofs) => {
    jofs.save().then((result) => {
      $scope.jofs.push(result);
      $scope.recalculateGroupedJOFS();
    });
  };

  $scope.deletePLI = function(pli) {
    const index = $scope.jofs.indexOf(pli);
    pli['delete']().then(() => {
      $scope.jofs.splice(index, 1);
      $scope.recalculateGroupedJOFS();
    });
  };

  $scope.valueWithFallback = (a, b) => {
    if (a === null || a === undefined) {
      return b;
    } else {
      return a;
    }
  };
});
