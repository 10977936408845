angular.module('RocketWash').factory(
  'PriceList',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/price_lists/" + (context.id || '') },
      name: 'price_list',
    });

    resource.all = (options) => {
      return resource.$get(
        `${resource.resourceUrl()}/all`,
        options,
      );
    };

    return resource;
  },
);
