angular.module('RocketWash').controller(
  'WashDashboardControlPanelController',
  (
    $http, $q, $scope, $translate, $uibModal, $compile, $timeout,
    userSession, viewStore, ServiceLocationLane, Schedule, Reservation, CameraEvent,
    SettingValue, alertService, screenService, PreReservation, reservationForm, rwApi,
    ActionCableChannel,
  ) => {
    const settingsReady = SettingValue.query().then(function(settingValues) {
      $scope.settingValues = settingValues;

      $scope.calendarSlotDuration = $scope.settingValues
        .find(sv => sv.key === 'control_panel_calendar_slot_duration').value;
    });

    var controlPanelChannel = new ActionCableChannel("ControlPanelChannel", {
      organization_id: userSession.organization.id,
      service_location_id: userSession.service_location.id,
      name: 'control_panel',
    });
    controlPanelChannel.subscribe((data) => {
      switch (data.message) {
        case 'reservation_saved':
          $scope.refetchEvents();
          break;
        case 'online_reservation_created':
          alertService.show({
            text: $translate.instant('dashboard.control_panel.online_reservation_created'),
          });
          $scope.refetchEvents();
          break;
        case 'online_reservation_cancelled':
          alertService.show({
            text: $translate.instant('dashboard.control_panel.online_reservation_cancelled'),
            type: 'error',
          });
          $scope.refetchEvents();
          break;
        case 'check_printed':
          $scope.refetchEvents();
          break;
        case 'check_cancelled':
          $scope.refetchEvents();
          break;
      };
    }).then(() => {
      $scope.$on("$destroy", function(){
        controlPanelChannel.unsubscribe();
      });
    });

    // VK pixel
    (window.Image ? (new Image()) : document.createElement('img')).src = 'https://vk.com/rtrg?p=VK-RTRG-214061-b2pQ3';

    $scope.serviceLocationLanes = [];
    $scope.serviceLocationLaneIds = [];
    $scope.schedules = {};
    $scope.canVisit = userSession.can('visit', 'DashboardTab') || userSession.is('admin');
    $scope.showCalendar = false;
    $scope.cameraEventsVisibility = {show: false};

    $scope.refetchEvents = _.debounce(() => {
      $('#rw-fullcalendar').fullCalendar('refetchEvents');
    }, 50);

    const sllReady = ServiceLocationLane.query().then((serviceLocationLanes) => {
      $scope.serviceLocationLanes = _.sortBy(serviceLocationLanes.filter(lane => lane.active), 'name');
      $scope.serviceLocationLaneIds = $scope.serviceLocationLanes.map(lane => lane.id);

      $scope.resources = $scope.serviceLocationLanes.map(lane => ({
        id: lane.id.toString(),
        title: lane.name,
      }));
    });
    const schReady = Schedule.query().then((schedules) => {
      $scope.schedules = _.groupBy(schedules, s => s.dayName);
    });

    const forceTimeZone = (date) => {
      const zone = userSession.time_zone.utc_offset / 3600;
      return moment(date).utcOffset(zone).subtract(zone, 'hours');
    };
    $scope.getCurrentDate = () => (
      moment($('#rw-fullcalendar').fullCalendar('getDate')).utcOffset(moment().utcOffset()).add(-moment().utcOffset(), 'minutes')
    );
    const daySchedule = () => {
      const key = $scope.getCurrentDate().locale('en').format('dddd');
      return $scope.schedules && $scope.schedules[key][0];
    };
    const prevDaySchedule = function () {
      const key = $scope.getCurrentDate().subtract(1, 'day').locale('en').format('dddd');
      return $scope.schedules && $scope.schedules[key][0];
    };
    const timeDisabled = (time) => {
      console.log('curr', $scope.getCurrentDate().format());
      const scheduleToTime = timeOfDay => $scope.getCurrentDate().startOf('day').add(timeOfDay);
      const prevDay = prevDaySchedule();
      const currDay = daySchedule();
      const prevNightStart = $scope.getCurrentDate().startOf('day');
      const prevNightEnd = scheduleToTime(prevDay.nightOpenAt) > scheduleToTime(prevDay.nightCloseAt) ?
        scheduleToTime(prevDay.nightCloseAt) :
        prevNightStart;
      const dayStart = scheduleToTime(currDay.dayOpenAt);
      const dayEnd = scheduleToTime(currDay.dayCloseAt);
      const nightEndTime = $scope.getCurrentDate().endOf('day');
      const nightStart = scheduleToTime(currDay.nightOpenAt);
      const nightEnd = scheduleToTime(currDay.nightOpenAt) > scheduleToTime(currDay.nightCloseAt) ?
        nightEndTime :
        scheduleToTime(currDay.nightCloseAt);
      const secondNightStart = scheduleToTime(currDay.secondNightOpenAt);
      const secondNightEnd = scheduleToTime(currDay.secondNightOpenAt) > scheduleToTime(currDay.secondNightCloseAt) ?
          nightEndTime :
          scheduleToTime(currDay.secondNightCloseAt);
      const disabled = (
        !(
          (prevNightStart <= time && time < prevNightEnd) ||
            (dayStart <= time && time < dayEnd) ||
            (nightStart <= time && time < nightEnd) ||
            (secondNightStart <= time && time < secondNightEnd)
        )
      );
      return disabled;
    };

    const compiledEvent = $compile(angular.element('<rw-fc-event>'));

    // resources for directives
    $scope.humanizeTime = time => moment(time).format('HH:mm');
    $scope.click = (reservation) => {
      const idx = $scope.serviceLocationLaneIds.indexOf(reservation.serviceLocationLaneId);
      reservation.openLeft = idx >= $scope.serviceLocationLaneIds.length / 2;
      reservation.touched = true;
      if (!reservation.selected) {
        $scope.reservations.forEach((x) => {
          x.selected = false;
        });
        reservation.selected = true;
        // XXX: danger, jailbreaking
        $('#rw-fullcalendar').find('.fc-event-container').css('z-index', '4');
        $(`#rw-fc-event-${reservation.id}`).closest('.fc-event-container').css('z-index', '5');
        return;
      }
      $scope.edit(reservation);
    };
    $scope.edit = (reservation) => {
      reservation.selected = false;

      return reservationForm.reservations.editReservationPopup(reservation).finally(() => {
        $timeout(() => {
          $('#rw-fullcalendar').fullCalendar('refetchEvents');
        }, 1000);
      });
    };
    $scope.close = (reservation) => {
      reservation.selected = false;
    };

    // end resources for directives

    // fullcalendar ignores some permission, overriding all
    const toggleEventEditable = (event, editable) => {
      if (typeof editable === 'undefined') {
        console.error('editable is required');
      }
      ['editable', 'startEditable', 'durationEditable', 'resourceEditable'].forEach((field) => {
        event[field] = editable;
      });
    };

    const updateReservationOrPreReservation = (event, callback) => {
      console.log('get reservation');
      console.log(new Date().getTime());
      toggleEventEditable(event, false);

      let path = null;
      if (event.type == 'Reservation') { path = 'reservation_form/reservations' };
      if (event.type == 'PreReservation') { path = 'operator/pre_reservations' };
      
      callback(event);

      rwApi.sendRequest({
        method: 'PUT',
        path: path,
        data: {
          id: event.id,
          time_start: event.time_start,
          full_duration: event.full_duration,
          service_location_lane_id: event.service_location_lane_id,
          service_location_id: userSession.service_location.id,
        },
      }).then((data) => {
        $('#rw-fullcalendar').fullCalendar('refetchEvents');
        toggleEventEditable(event, true);
      });
    };

    let renderReservation = (event, element, _view) => {
      const content = angular.element('<rw-fc-event>')
      .attr('data-reservations', 'reservations')
      .attr('data-reservation-id', event.id.toString());
      const reservation = $scope.reservations.find(x => x.id === event.id);
      if (event.editable === undefined) {
        const editable = !userSession.isEditionForDatePrevented(moment(reservation.timeStart));
        toggleEventEditable(event, editable);
      }
      const safeCarMake = _.get(reservation, 'car.carMake.name', '');
      const safeCarModel = _.get(reservation, 'car.carModel.name', '');
      const safeTag = _.get(reservation, 'car.tag', '');
      const contractor = _.get(reservation, 'car.contractor');
      const safeName = _.get(contractor, 'name');
      const safePhone = _.get(contractor, 'phone');
      content.html(`
                   <div class="order-block undo-box-sizing ${reservation.statusCategory()}">
                   <div class="main-block">
                   <p class="car-brand">
                   ${safeCarMake}
                   ${safeCarModel}
                   </p>
                   <p class="car-number">
                   ${safeTag || ''}
                   </p>
                   <p class="client-name">
                   ${safeName}
                   </p>
                   <p class="client-phone">
                   ${safePhone}
                   </p>
                   <div class="icons-container">
                   ${reservation.fullyPaid ? '<i class="icon-sprite coins-icon"></i>' : ''}
                   ${reservation.mobile() ? '<i class="icon-sprite mobile-icon"></i>' : ''}
                   </div>
                   </div>
                   </div>
                   `);
                   // element.empty();
                   element.append(content);

                   const newScope = angular.extend($scope.$new(), {
                     reservation: $scope.reservations.find(x => x.id === event.id),
                   });
                     newScope.$evalAsync(() => {
                       compiledEvent(newScope, (clone) => {
                         // element.empty();
                         element.find('rw-fc-event').remove();
                         element.append(clone);
                       });
                     });
    }

    let renderPreReservation = (event, element, _view) => {
      const preReservation = event;
      const content = angular.element('<rw-fc-event>')
      .attr('data-pre-reservations', 'pre-reservations')
      .attr('data-pre-reservation-id', event.id.toString());
      if (event.editable === undefined) {
        const editable = !userSession.isEditionForDatePrevented(moment(preReservation.timeStart));
        toggleEventEditable(event, editable);
      }
      const newElement = angular.element(`
                                         <div class="order-block undo-box-sizing pre-reservation" ng-click='clickPreReservation(preReservation)'>
                                         <div class="main-block">
                                         <p class="car-number">
                                         ${preReservation.car_tag || ''}
                                         </p>
                                         <p class="comment">
                                         ${preReservation.comment || ''}
                                         </p>
                                         </div>

                                         <div class="convert-to-reservation" ng-click='convertToReservation(preReservation, $event)'>
                                         <i class='fa fa-plus-square'>
                                         </i>
                                         </div>
                                         </div>
                                         `);

                                         const newScope = angular.extend($scope.$new(), {
                                           preReservation: preReservation,
                                           clickPreReservation: (preReservation) => {
                                             reservationForm.preReservations.editPreReservationPopup(preReservation).finally(() => {
                                               console.log('updating');
                                               $('#rw-fullcalendar').fullCalendar('refetchEvents');
                                             });
                                           },
                                           convertToReservation: (preReservation, event) => {
                                             $scope.createNewReservation({
                                               date: preReservation.time_start,
                                               resourceId: preReservation.service_location_lane_id,
                                               preReservation: preReservation,
                                             });

                                             event.stopPropagation();
                                             event.preventDefault();
                                           },
                                         });
                                         newScope.$evalAsync(() => {
                                           const compiledElement = $compile(newElement);
                                           compiledElement(newScope, (clone) => {
                                             element.find('rw-fc-event').remove();
                                             element.append(clone);
                                           });
                                         });
    }

    let renderCameraEvent = (event, element, _view) => {
      const content = angular.element('<rw-fc-event>')
      .attr('data-camera-event-id', event.id.toString());
      const cameraEvent = $scope.cameraEvents.find(x => x.id === event.id);
      toggleEventEditable(event, false);

      const safeCarNumber = cameraEvent.carNumber || '';
      const safeDirection = cameraEvent.direction || '';
      const directionText = $translate.instant('dashboard.control_panel.camera_event.direction.' + safeDirection);
      const safeImage = cameraEvent.imageCarFull.url;
      content.html(`
                   <div class="undo-box-sizing ${safeDirection}">
                   <div class="main-block">
                   <p class="car-number">
                   ${safeCarNumber}
                   </p>
                   <p class="direction">
                   ${directionText}
                   </p>
                   </div>
                   </div>
                   `);
                   element.empty();
                   element.append(content);
    }

    $scope.createNewReservation = ({date, resourceId, cameraEventId=null, preReservation=null, autostartCamera=false, reservationAttributes={}}) => {
      // HOTFIX: fullcalendar returns date without timezone
      if (timeDisabled(moment(date))) {
        return { error: 'time_disabled' };
      }
      // date = forceTimeZone(date);
      if (userSession.isEditionForDatePrevented(date)) {
        return { error: 'date_is_not_editable' };
      }
      // Convention: resourceId should be a string
      resourceId = parseInt(resourceId, 10);
      const lane = $scope.serviceLocationLanes.find(sll => resourceId === sll.id);
      if (!lane) {
        return { error: 'lane_not_found' };
      }

      reservationForm.reservations.init({
        service_location_id: userSession.service_location.id,
        service_location_lane_id: lane.id,
        time_start: new Date(date),
        camera_event_id: () => cameraEventId,
      });

        reservationForm.config.setPreReservation(preReservation);
        reservationForm.config.setAutostartCamera(autostartCamera);

        return reservationForm.reservations.editReservationPopup().finally(() => {
          console.log('updating');
          $('#rw-fullcalendar').fullCalendar('refetchEvents');
        });
    }


    $q.all([settingsReady, sllReady, schReady]).then(() => {
      console.log('before', $scope.calendarSlotDuration);
      let timeColumnInitiated = false;
      let disabledTimeInitiated = false;
      $scope.calendarConfig = {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        droppable: true,
        nowIndicator: true,
        timezone: userSession.time_zone.identifier,
        loadingOverlayActive: false,
        editable: true,
        startEditable: true,
        durationEditable: true,
        resourceEditable: true,
        eventOverlap: false,
        defaultView: 'agendaDay',
        allDaySlot: false,
        firstDay: 1,
        slotLabelFormat: 'HH:mm',
        slotLabelInterval: $scope.calendarSlotDuration || '00:15:00',
        slotDuration: $scope.calendarSlotDuration || '00:15:00',
        snapDuration: '00:01:00',
        minTime: '00:00:00',
        maxTime: '24:00:00',
        scrollTime: `${moment().hour() - 1}:${moment().minute()}:00`,
        slotEventOverlap: false,
        lazyFetching: false,
        monthNames: Highcharts.getOptions().lang.months,
        monthNamesShort: Highcharts.getOptions().lang.shortMonths,
        dayNames: Highcharts.getOptions().lang.weekdays,
        dayNamesShort: Highcharts.getOptions().lang.weekdays,
        resources: $scope.resources || [],
        height: () => document.documentElement.clientHeight - 80,
          dragOpacity: '0.85',
        eventSources: [
          (start, end, timezone, callback) => {
            // TODO: wtf?
            const _ = Math.random();
            return $http.get(
              '/wash/reservations/events',
              { params: { start, end, timezone, _ } },
            ).then((response) => {
              $scope.reservations = response.data.map(x => new Reservation(x));
              callback(response.data);
            });
          },
          (start, end, timezone, callback) => {
            if (!$scope.cameraEventsVisibility.show) { return callback([]); };

            // TODO: wtf?
            const _ = Math.random();
            return $http.get(
              '/wash/camera_events/events',
              { params: { start, end, timezone, _ } },
            ).then((response) => {
              $scope.cameraEvents = response.data.map(x => new CameraEvent(x));
              callback(response.data);
            });
          },
          (start, end, timezone, callback) => {
            // TODO: wtf?
            const _ = Math.random();
            return $http.get(
              '/wash/pre_reservations/events',
              { params: { start, end, timezone, _ } },
            ).then((response) => {
              $scope.preReservations = response.data.map(x => new PreReservation(x));
              callback(response.data);
            });
          },
        ],
        buttonText: {
          today: $translate.instant('global.today'),
        },
        header: {
          left:   'today',
          center: '',
          right:  ''
        },
        titleFormat: 'dddd, MMM D, YYYY',
        eventDataTransform(eventData) {
          eventData.resourceId = eventData.service_location_lane_id.toString();

          return eventData;
        },
        eventRender(event, element, _view) {
          switch(event.type) {
            case 'Reservation': renderReservation(event, element, _view); break;
            case 'PreReservation': renderPreReservation(event, element, _view); break;
            case 'CameraEvent': renderCameraEvent(event, element, _view); break;
          }
        },
        dayClick(date, _clickEvent, _view, resource) {
          date.utcOffset(moment().utcOffset());
          date = date.add(-date.utcOffset(), 'minutes');
          console.log(date.format());

          reservationForm.config.calendar.clickedDate = date;
          reservationForm.config.calendar.sllId = resource.id;
          $scope.createNewReservation({date: date, resourceId: resource.id});
        },
        eventDragStart(event) {
          const delta = event.start.minutes() % 15;
          event.start = moment(event.start).subtract(delta, 'minutes');
          event.end = moment(event.end).subtract(delta, 'minutes');
        },
        eventDrop(event, delta) {
          $scope.calendarConfig.loadingOverlayActive = true;
          updateReservationOrPreReservation(event, (item) => {
            item.time_start = moment(item.time_start)
            .add(delta / 1000, 'seconds').format();
            item.service_location_lane_id = parseInt(event.resourceId, 10);
            $scope.calendarConfig.loadingOverlayActive = false;
          });
        },
        eventResize(event, delta) {
          $scope.calendarConfig.loadingOverlayActive = true;
          updateReservationOrPreReservation(event, (item) => {
            item.full_duration += delta.as('minutes');
            $scope.calendarConfig.loadingOverlayActive = false;
          });
        },
        eventAfterAllRender(view) {
          if (!disabledTimeInitiated) {
            disabledTimeInitiated = true;
            view.el.find('.fc-slats tr').each((index, row) => {
              let [hour, minute, second] = row.getAttribute('data-time').split(':');
              let timeOfDay = {hour, minute, second};
              let time = $scope.getCurrentDate().startOf('day').add(timeOfDay)

              let disabled = userSession.isEditionForDatePrevented(time) || timeDisabled(time);

              if (disabled) {
                row.classList.add('disabled');
              };
            });
          };

          if (!timeColumnInitiated) {
            timeColumnInitiated = true;
            setTimeout(() => {
              // Add time column overlay
              const fcSlats = view.el.find('.fc-slats').clone().addClass('only-for-time');
              $('.fc-view-container').prepend(fcSlats);

              // Sync time column overlay scroll with calendar body
              const scroller = view.el.find('.fc-scroller.fc-time-grid-container');
              const updateScroll = () => { fcSlats.scrollTop(scroller.scrollTop()); };
              scroller.on('scroll', updateScroll);
              updateScroll();

              // Add tr contents to split columns with border
              const borders = $(`<div class='sll-borders-container'>${$scope.serviceLocationLanes.map(() => "<div class='sll-border'></div>").join('')}</div>`);
              view.el.find('.fc-slats .fc-widget-content').each((index, el) => {
                el = $(el);
                if (!el.hasClass('fc-time')) {
                  el.append(borders.clone());
                }
              });
            }, 100);
          };
        },
        drop(date, e, ui, resourceId) {
          const type = e.target.getAttribute('type');

          switch(type) {
            case 'CameraEvent':
              const cameraEventId = e.target.getAttribute('camera_event_id');
            $scope.createNewReservation({date, resourceId, cameraEventId});
            break;
            case 'PreReservation':
              $scope.createPreReservation({date, resourceId});
            break;
            default:
          }
        },
      };

      $timeout(() => {
        $scope.showCalendar = true;
      });
    });
  },
);
