angular.module('RocketWash').controller('WashChartsServicesRevenueController', (
  $scope, $q, $filter, $translate, CarType, BasicService, ExtraService, ctgSelectService, ctSelectService
) => {
  $scope.ctgSelectService = ctgSelectService;
  $scope.ctSelectService = ctSelectService;

  $scope.parseSortOrder = (orderString) => {
    if (!orderString) {
      return;
    }
    $scope.sort = {
      field: orderString.startsWith('-') ? orderString.slice(1) : orderString,
      reverse: orderString.startsWith('-'),
    };
  };

  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  $scope.exportXLS = () => {
    window.open(`${$scope.env.apiUrl}/wash/charts/services_revenue.xlsx?${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.carTypes = [];

  const basic = BasicService.indexSimple().then((services) => {
    $scope.basicServices = services;
  });
  $q.all([basic]).then(() => {
    let categories;
    categories = _.map($scope.basicServices, 'category');
    categories = categories.concat(_.map($scope.extraServices, 'category'));
    categories = _.uniq(categories);
    categories = _.sortBy(categories, c => c);
    categories.unshift(undefined); // all
    $scope.categories = categories;
  });
  CarType.query().then((data) => {
    const carTypes = _.sortBy(data, 'name');
    carTypes.unshift(undefined); // all
    $scope.carTypes = carTypes;
  });
  $scope.carTypeName = (typeId) => {
    const carType = $scope.carTypes.find(ct => ct.id === typeId);
    return carType && carType.name;
  };
  $scope.serviceTypes = [
    {
      name: $filter('translate')('charts.services_revenue.service_types.all'),
      value: undefined,
    }, {
      name: $filter('translate')('charts.services_revenue.service_types.BasicService'),
      value: 'BasicService',
    }, {
      name: $filter('translate')('charts.services_revenue.service_types.ExtraService'),
      value: 'ExtraService',
    },
  ];

  $scope.dateFields = ['paid_at', 'time_start', 'time_end']

  $scope.pds = $scope.pageDataService.new({
    page: 'services_revenue_chart',
    filters: {
      attributes: [
        { name: 'service_name', type: 'string' },
        { name: 'category', type: 'string' },
      ],
      scopes: [
        'service_name', 'category',
      ],
    },
    pg: {
      url: 'wash/charts/services_revenue',
      query: {
        fromToField: 'time_end',
        predicate: '-time_end',
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.$watch('ctgSelectService.selected.id', () => {
    $scope.pds.pg.query.car_types_group_id = $scope.ctgSelectService.selected.id;
    $scope.pds.pg.query.car_type_id = undefined;
    $scope.updatePage();
  });

  $scope.updateTable = () => {
    $scope.$broadcast('pds-update');
  };

  $scope.sumColumn = (attribute) => {
    return $scope.pds.pg.items.map(item => item[attribute]).reduce((a, b) => a + b, 0);
  };
});
