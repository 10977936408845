angular.module('RocketWash').controller('WashChartsSmsController', ($scope, Sms, total) => {
  $scope.total = total;
  Sms.$get('wash/sms/balance').then((data) => {
    $scope.balance = data.balance;
  });

  $scope.pds = $scope.pageDataService.new({
    page: 'sms',
    filters: {
      attributes: [
        { name: 'receiver_name', type: 'string' },
        { name: 'receiver_phone', type: 'string' },
        { name: 'sender_name', type: 'string' },
        { name: 'status', type: 'string' },
        { name: 'text', type: 'string' },
      ],
      scopes: ['receiver_name', 'receiver_phone', 'sender_name', 'status', 'text'],
    },
    pg: {
      url: '/wash/sms',
      model: Sms,
      query: {
        fromToField: 'created_at',
        predicate: '-created_at',
        reverse: true,
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.senderName = (sms) => {
    const superuser = sms.sentBySuperuser ? 'Администратор (superuser)' : null;
    const sender = (sms.sender && sms.sender.name);
    const serLoc = (sms.serviceLocation && sms.serviceLocation.name);
    const org = (sms.organization && sms.organization.name);
    return superuser || sender || serLoc || org;
  };

  $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);
  $scope.toggleAll = () => {
    const newValue = !$scope.allExpanded();
    $scope.pds.pg.items.forEach((x) => {
      x.expanded = newValue;
    });
  };
});
