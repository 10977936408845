import { env, secretKey } from '@env';
const countries = require('country-list/data.json');
const PARTNER_CODE_KEY = 'signUpPartnerCode';
angular.module('RocketWash').controller(
  'WashSignUpController',
  ($scope, $http, $timeout, $translate, $state, $stateParams, $cookies, userSession, alertService, Plan) => {
    $scope.alertService = alertService;
    $scope.countries = countries;
    $scope.currentYear = moment().format('YYYY');
    $scope.secretKey = secretKey.key;

    let partnerCode = $stateParams.partner_code;
    if (partnerCode) {
      $cookies.put(PARTNER_CODE_KEY, partnerCode);
    };
    $scope.partnerCode = partnerCode || $cookies.get(PARTNER_CODE_KEY);

    Plan.query({}, {queryRole: '/'}).then((plans) => {
      $scope.plans = plans;

      $scope.userOptions.plan_id = plans.find(p => p.default).id;
    });

    $scope.getTime = function() {
      const rounded_time = new Date(Math.floor(Date.now() / 300000) * 300000);

      const day = rounded_time.getDate().toString().padStart(2, '0');
      const month = (rounded_time.getMonth() + 1).toString().padStart(2, '0');
      const year = rounded_time.getFullYear();
      const hours = rounded_time.getHours().toString().padStart(2, '0');
      const minutes = rounded_time.getMinutes().toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    function encryptMessage(message, key) {
      let encryptedMessage = '';
      let keyIndex = 0;

      for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i) + key.charCodeAt(keyIndex);
        encryptedMessage += String.fromCharCode(charCode);

        keyIndex++;
        if (keyIndex === key.length) {
          keyIndex = 0;
        }
      }
      return btoa(encryptedMessage);
    };

    $scope.userOptions = {
      role: 'organization_director',
      partner_code: $scope.partnerCode,
    };

    $scope.progress = { registration: false };

    const runValidations = () => {
      let errors = [];

      if (!$scope.userOptions.organization_name) {
        errors.push('organization_name');
      }

      if (!$scope.userOptions.user_name) {
        errors.push('user_name');
      }

      if (!$scope.userOptions.user_phone || $scope.userOptions.user_phone.indexOf('_') >= 0) {
        errors.push('user_phone');
      }

      if (!$scope.userOptions.user_email) {
        errors.push('user_email');
      }

      errors.forEach((error) => {
        alertService.show({
          text: $translate.instant(`sign_up.errors.${error}`),
          type: 'error'
        });
      });

      return errors.length == 0;
    };

    $scope.register = () => {
      const valid = runValidations();

      if (!valid) { return };
      let user_phone = $scope.userOptions.user_phone
      let user_email = $scope.userOptions.user_email
      let message = `${user_phone}${$scope.getTime()}${user_email}`;
      $scope.userOptions.token = encryptMessage(message, $scope.secretKey);

      $scope.progress.registration = true;
      alertService.show({
        text: $translate.instant('sign_up.wait_please'),
      });

      $http.post('/registrations', $scope.userOptions).then((response) => {
        $scope.progress.registration = false;
        alertService.show({
          text: $translate.instant('sign_up.success')
        });

        if (window.ym) {
          ym(25870445, 'reachGoal', 'just_registered');
        }

        let org = response.data.organization;
        let user = response.data.user;

        let newUrl = window.location.href.replace(
          new RegExp(`(https?:\\/\\/)(.*)(${env.appSubdomains})(\\.rocketwash[^\\/]*).*`),
          '$1' + org.subdomain_name + '.$3$4/#!/sign_in?just_registered=true&phone=' + user.phone)
        window.location.href = newUrl;
      }, () => {
        $scope.progress.registration = false;
        alertService.show({
          text: $translate.instant('errors.other'),
          type: 'error',
          timeout: 60000
        });
      });
    };
  },
);
