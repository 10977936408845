angular.module('RocketWash').component('rwReservationPayments', {
  templateUrl: 'components/rw-reservation/steps/payments/index.slim',
  bindings: {
  },
  controller: function (
    $scope, userSession, total, PaymentConfirmation, reservationForm,
  ) {
    $scope.rf = reservationForm;
    $scope.userSession = userSession;
    $scope.isYaFuelAvailable = userSession.service_location.yandex_fuel_available

    reservationForm.payments.regroupPayments();

    $scope.$watch('rf.payments.all', () => {
      $scope.rf.payments.calculate();
    }, true);

    $scope.groupSum = (groupId) => {
      return _.sumBy(($scope.rf.payments.grouped[groupId] || []), 'amount');
    };
  },
});
