angular.module('RocketWash').controller('AdminSettingsGeneralBaseController', (
  $scope, $translate, SettingValue, $q, $filter,
  FinanceClause, PaymentType, Organization, serverConstants,
) => {
  $scope.organizations = [];
  $scope.fcs = [];
  $scope.pts = [];

  serverConstants.promise.then(() => {
    $scope.settingValuesForSelect = serverConstants.setting_values;
  });

  $scope.settingValueTranslationForSelect = (key, value) => {
    return $translate.instant(`setting_value.${key}.${value}`);
  };

  $scope.foobar = key => `foobar:${key}`;

  FinanceClause.query({}, 'index_list').then((fcs) => {
    $scope.fcs = _.sortBy(fcs, fc => fc.calculated_index);
    $scope.fcs.push({
      id: null,
      calculated_index: '-',
      name: '-',
    });
  });
  PaymentType.query().then((pts) => {
    $scope.pts = pts;
  });
  Organization.query({}, 'all').then((orgs) => {
    $scope.organizations = orgs;
  });
  $scope.orgName = (orgId) => {
    const organization = $scope.organizations.find(org => org.id === orgId);
    return organization && organization.name;
  };
  const svPromise = SettingValue.query().then((values) => {
    $scope.values = values;
  });
  $scope.allReady = false;
  const allPromise = $q.all([serverConstants.promise, svPromise]);
  allPromise.then(() => {
    $scope.allReady = true;
    $scope.groupedValues = {};
    $scope.values.forEach((sv) => {
      if (sv && sv.value && sv.value.match(/^\d+$/)) {
        sv.value = parseFloat(sv.value);
      }
      $scope.groupedValues[sv.key] = sv;
    });
    $scope.financialOperationTypes = $scope.serverConstants.financial_operation_types
      .map(x => $filter('toSnakeCase')(x));

    $scope.registerCheckKeys = [
      'register_check_item_tax_type',
      'register_check_item_payment_method_type',
      'register_check_item_payment_subject_type',
      'register_check_taxation_system',
      'register_check_automat_number',
      'register_check_settlement_address',
      'register_check_settlement_place',
    ];

    $scope.tinkoffSettingKeys = [
      'tinkoff_terminal_key',
      'tinkoff_terminal_password',
      'tinkoff_payment_description',
      'tinkoff_mobile_terminal_key',
      'tinkoff_mobile_terminal_password',
      'tinkoff_mobile_payment_description',
    ];
  });
  $scope.save = (kind) => { kind.save(); };
  $scope.toFcName = (fcId) => {
    const fc = $scope.fcs.find(x => x.id === parseInt(fcId, 10));
    return fc && `${fc.calculatedIndex} ${fc.name}`;
  };
  $scope.toPtName = (ptId) => {
    const pt = $scope.pts.find(x => x.id === parseInt(ptId, 10));
    return pt && pt.name;
  };
});
