angular.module('RocketWash').controller(
  'WashSettingsMobileSettingsSlLocationPopupController',
  (
    $scope, $route, $location, $window, $timeout,
    userSession, ServiceLocation, SettingValue, serverConstants, Organization,
  ) => {


    // $scope.moveMarkerToMapCenter = () => {
    //   $timeout(() => {
    //     $scope.geoObjectSource.geometry.coordinates = angular.copy($scope.mapCenter);
    //   });
    // };
    //
    // $scope.afterMapInit = (target) => {
    //   $scope.map = target;
    //   $scope.moveMarkerToMapCenter();
    // };
    //
    // $scope.geoObjectSource = {};
    // $scope.geoObjectOptions = {};

    $scope.circleLayer = null;
    const showCircle = (map) => {

      const geojsonMarkerOptions = {
        // radius: parseInt($scope.search.radius),
        fillColor: "#ff7800",
        color: "#000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.4
      };

      if ($scope.circleLayer) {
        $scope.circleLayer.remove();
      }

      $scope.circleLayer = L.circleMarker([parseFloat($scope.sl.latitude), parseFloat($scope.sl.longitude)], geojsonMarkerOptions);

      $scope.circleLayer.addTo(map);
    }

    ServiceLocation.get(userSession.service_location.id).then((sl) => {
      $scope.sl = sl;

      const map = L.map('service-location-map').setView([parseFloat(sl.latitude), parseFloat(sl.longitude)], 8);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      showCircle(map);

      map.on('click', (e) => {
        sl.latitude = e.latlng.lat;
        sl.longitude = calculateValidLng(e.latlng.lng);
        showCircle(map);
        map.setView([sl.latitude, sl.longitude], 8);
      });

    });

    // Elasticsearch only allows longitude values from range -180..180
    // Clicking map can give bigger values, so it should be recalculated
    const calculateValidLng = (lng) => {
      return ((lng + 180) % 360) - 180;
    }

    // $scope.geoObjectSource = {
    //   geometry: {
    //     type: "Point",
    //     coordinates: [0,0]
    //   },
    // };
    //
    // $scope.geoObjectOptions = {
    //   preset: 'islands#circleIcon',
    //   iconColor: '#3caa3ca',
    //   draggable: true,
    // };
    //
    // $scope.mapClick = (event) => {
    //   const coords = event.get('coords');
    //   $scope.geoObjectSource.geometry.coordinates = coords;
    //   [$scope.sl.latitude, $scope.sl.longitude] = coords;
    // };
    //
    // $scope.geoObjectInitialized = (target) => {
    //   $scope.geoObject = target;
    //   $scope.moveMarkerToMapCenter();
    // };

    $scope.save = () => {
      $scope.sl.save().then(() => {
        $scope.$dismiss();
      });
    };
  },
);
