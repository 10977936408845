import { warn } from "fullcalendar";

angular.module('RocketWash').controller('WashControlBoxesControlController', (
  $scope, $q, $http, $translate, $timeout, $compile, $filter,
  userSession, ServiceLocationLane, RecognizedReservation, LaneCameraEvent, Reservation,
) => {
  const DISABLED_LANE_ID = 'inactive';
  $scope.reservations = [];
  $scope.stats = {};
  $scope.selected = {};
  const sllReady = ServiceLocationLane.query().then((serviceLocationLanes) => {
    $scope.serviceLocationLanes = _.sortBy(serviceLocationLanes.filter(lane => lane.active), 'name');
    $scope.serviceLocationLaneIds = $scope.serviceLocationLanes.map(lane => lane.id);

    $scope.resources = $scope.serviceLocationLanes.map(lane => ({
      id: lane.id.toString(),
      title: lane.name,
    }));
  });

  $q.all([sllReady]).then(() => {
    $scope.calendarConfig = {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      nowIndicator: true,
      timeZone: userSession.time_zone.identifier,
      loadingOverlayActive: false,
      defaultView: 'timelineDay',
      allDaySlot: false,
      firstDay: 1,
      slotLabelFormat: 'HH:mm',
      slotLabelInterval: '00:15:00',
      slotDuration: '00:15:00',
      snapDuration: '00:01:00',
      minTime: '00:00:00',
      maxTime: '24:00:00',
      scrollTime: `${moment().hour() - 1}:${moment().minute()}:00`,
      slotEventOverlap: false,
      lazyFetching: false,
      monthNames: Highcharts.getOptions().lang.months,
      monthNamesShort: Highcharts.getOptions().lang.shortMonths,
      dayNames: Highcharts.getOptions().lang.weekdays,
      dayNamesShort: Highcharts.getOptions().lang.weekdays,
      resources: $scope.resources || [],
      dragOpacity: '0.85',
      height: 250,
      eventSources: [
        (start, end, timezone, callback) => {
          // TODO: wtf?
          const _ = Math.random();

          return $http.get(
            '/wash/recognized_reservations/events',
            { params: { start, end, timezone, _ } },
          ).then((response) => {
            $scope.reservations = response.data;

            let avgDuration = 0;
            if ($scope.reservations.length) {
              avgDuration = Math.round($scope.reservations.reduce((sum, r) => r.duration + sum, 0) / $scope.reservations.length);
            };

            $http.get('/wash/charts/finished_reservations_count', {
              params: {
                time_from: start,
                time_to: end,
              }
            }).then((resp) => {
              $scope.finishedCount = resp.data.finished_count;

              $scope.stats = {
                count: $scope.reservations.length,
                avgDuration: avgDuration,
                finishedCount: $scope.finishedCount,
              }

              initReservationsByTimeChart();
              initCarsByLaneChart();

              callback(response.data);
            });

          });
        },
        (start, end, timezone, callback) => {
          // TODO: wtf?
          const _ = Math.random();
          return $http.get(
            '/wash/reservations/events',
            { params: { start, end, timezone, _ } },
          ).then((response) => {
            $scope.manualReservations = response.data;

            response.data.forEach((r) => {
              r.title = `${r.full_duration}мин - ${r.car.car_make.name} ${r.car.car_model.name} ${r.car.tag}`
            });
            callback(response.data);
          });
        },
      ],
      buttonText: {
        today: $translate.instant('global.today'),
      },
      resourceLabelText: 'Пост',
      resourceAreaWidth: '15%',
      header: false,
      titleFormat: 'dddd, MMM D, YYYY',
      eventDataTransform(eventData) {
        eventData.resourceId = eventData.service_location_lane_id.toString();

        return eventData;
      },
      eventRender(event, element, _view) {
        const text = element.text();
        const reservation = $scope.manualReservations.find(x => x.id === event.id);

        if (!reservation) { return }

        const timeStart = $filter('formatTime')(_.get(reservation, 'time_start'), 'time');
        const timeEnd = $filter('formatTime')(_.get(reservation, 'time_end'), 'time');
        const duration = $filter('humanizeDuration')(_.get(reservation, 'full_duration'));
        const safeCarMake = _.get(reservation, 'car.car_make.name', '');
        const safeCarModel = _.get(reservation, 'car.car_model.name', '');
        const safeTag = _.get(reservation, 'car.tag', '');
        const contractor = _.get(reservation, 'car.contractor');
        const safeName = _.get(contractor, 'name');
        const safePhone = _.get(contractor, 'phone');
        const services = _.get(reservation, 'attached_services');

        const servicesList = services.map(s => {
          let paddingLeft = services.indexOf(s) == 0 ? '5px' : '20px';
          return `<span style="padding-left: ${paddingLeft}; font-weight: bold">${s.name}</span>`
        }).join('<br>');

        const content = $compile(`
          <span class="tooltip-container">
            <div class="fc-content">
              <span class="fc-title" style="top: 0px; left: 0px;">${text}</span>
            </div>
            <div class="fc-bg"></div>
            <md-tooltip md-direction="top" style="font-size: 1.2rem">
              <p class="time"
                 style="border-bottom: 0.1rem solid #DFDFDF;
                        margin-bottom: 5px;
                        padding-bottom: 5px">
                <i class="fa fa-clock-o" style="padding-right: 5px"></i>
                <strong>${timeStart}</strong> - <strong>${timeEnd}</strong>
                <span class="light-gray">(${duration})</span>
              </p>
              <p class="car-brand bold">
                <i class="icon-sprite car-icon"></i>
                <span style="padding-left: 5px;font-size: 1.4rem">${safeCarMake || ''} ${safeCarModel || ''}</span>
                ${reservation.fully_paid ? '<i class="icon-sprite coins-icon" style="float:right"></i>' : ''}
              </p>
              <p class="car-number bold" style="margin-left: 22px">
                ${safeTag || ''}
                ${reservation.channel == 'mobile' ? '<i class="icon-sprite mobile-icon" style="float:right"></i>' : ''}
              </p>
              <p class="client-name">
                <i class="icon-sprite control-icon-small"></i>
                <span style="padding-left: 5px">${safeName}</span>
              </p>
              <p class="client-phone" style="margin-left: 20px;">
                ${safePhone || ''}
              </p>
              <p class="divider"
                 style="border-bottom: 0.1rem solid #DFDFDF;
                        margin-bottom: 5px;
                        padding-bottom: 5px">
              </p>
              <p class="attached-services">
                <i class="icon-sprite list-icon-small"></i>
                ${servicesList}
              </p>
            </md-tooltip>
          </span>
        `);
        element.html(content($scope));

      },
      dayClick(date, _clickEvent, _view, resource) {
        date.utcOffset(moment().utcOffset());
        date = date.add(-date.utcOffset(), 'minutes');
        LaneCameraEvent.nearest({date: date.toISOString(), service_location_lane_id: resource.id}).then((lce) => {
          $scope.selected.lane = resource;
          $scope.selected.lce = {};
          $scope.setForm('lce', lce);
        });
      },
      eventClick(event, jsEvent, view) {
        $scope.setForm('rr', event);
        if (event.type == "Reservation") {
          const reservation = new Reservation({ id: event.id });
          reservation.getLanesWorkloadData().then((rr) => {
            $scope.selected.rr = rr;
            $scope.selected.rr.editable = false;
            $scope.selected.rr.selectedLce = rr.imagesDuring[0];
          });
        };

        if (event.type == "RecognizedReservation") {
          RecognizedReservation.get({id: event.id}).then((rr) => {
            $scope.selected.rr = rr;
            $scope.selected.rr.editable = true;
            $scope.selected.rr.selectedLce = rr.imagesDuring[0];
          });
        }
      },
      eventAfterAllRender(view) {
        resetLaneWarnings(view);

        let mismatches = mismatchingLanes();
        mismatches.forEach(laneId => {
          view.el.find(`td.fc-resource-area tr[data-resource-id=${laneId}]`).each((_, row) => {
            row.style.backgroundColor = '#f7d0d1';

            appendWarningForLane(row);
          })
        });
      },
    };

    $timeout(() => {
      $scope.showCalendar = true;
    });
  });

  $scope.setForm = (variable, value) => {
    $scope.selected.rr = undefined;
    $scope.selected.lce = undefined;

    if (variable) {
      $scope.selected[variable] = value;
    };
  };

  const initReservationsByTimeChart = () => {
    if ($scope.reservationsByTimeChart) {
      $scope.reservationsByTimeChart.destroy();
      $scope.reservationsByTimeChart = null;
    }

    if ($scope.reservations.length == 0) {
      return;
    }

    let groups = [
      {
        name: 'До 15 мин',
        maxDuration: 15,
      },
      {
        name: '15-30 мин',
        maxDuration: 30,
      },
      {
        name: '30-45 мин',
        maxDuration: 45,
      },
      {
        name: '45-60 мин',
        maxDuration: 60,
      },
      {
        name: '60-75 мин',
        maxDuration: 75,
      },
      {
        name: '75-90 мин',
        maxDuration: 90,
      },
      {
        name: '90-105 мин',
        maxDuration: 105,
      },
      {
        name: 'Более 105 мин',
        maxDuration: 1000000,
      },
    ];

    groups.forEach((group) => {
      group.y = 0;
    });

    $scope.reservations.forEach((r) => {
      groups.forEach((group) => {
        if (r.processed) { return };

        group.y = group.y;
        if (r.duration < group.maxDuration) {
          group.y = group.y + 1;
          r.processed = true;
        }
      });
    });

    $scope.reservationsByTimeChart = Highcharts.chart({
      chart: {
        renderTo: 'reservations-by-time-chart',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Время выполнения заказа'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}шт.</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}шт',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },
      series: [{
        name: 'Кол-во',
        colorByPoint: true,
        data: groups,
      }]
    });
  };

  const initCarsByLaneChart = () => {

    if ($scope.carsByLaneChart) {
      $scope.carsByLaneChart.destroy();
      $scope.carsByLaneChart = null;
    }

    if ($scope.reservations.length == 0) {
      return;
    }

    let groups = $scope.resources.map((lane) => {
      return { id: lane.id, name: lane.title, y: 0 }
    });

    // Special group for disabled lanes
    groups.push({
      id: DISABLED_LANE_ID,
      name: 'Неактивные посты',
      y: 0,
    });

    $scope.reservations.forEach((r) => {
      let group = groups.find((g) => {
        return g.id == r.service_location_lane_id;
      });

      // If no group was found, we use special group for disabled lanes
      if (!group) {
        groups.find(g => g.id == DISABLED_LANE_ID).y += 1;
      } else {
        group.y += 1;
      }
    });

    $scope.carsByLaneChart = Highcharts.chart({
      chart: {
        renderTo: 'cars-by-lane-chart',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Количество автомобилей по постам'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}шт.</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}шт',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
            }
          }
        }
      },
      series: [{
        name: 'Кол-во',
        colorByPoint: true,
        data: groups,
      }]
    });
  };

  const saveRR = (rr) => {
    const rrToSave = new RecognizedReservation({
      id: rr.id,
      timeStart: rr.timeStart,
      timeEnd: rr.timeEnd,
    });
    rrToSave.save().then((rr) => {
      $scope.setForm('rr', rr);
      $scope.selected.rr = rr;
      $scope.selected.rr.selectedLce = rr.imagesDuring[0];
      $('#rw-fullcalendar').fullCalendar('refetchEvents');
    });
  };

  $scope.updateStart = (lce) => {
    if (lce) {
      $scope.selected.rr.selectedLce = lce;
    };
    $scope.selected.rr.timeStart = moment($scope.selected.rr.selectedLce.createdAt).subtract(1, 's').toISOString();
    saveRR($scope.selected.rr);
  };

  $scope.updateEnd = (lce) => {
    if (lce) {
      $scope.selected.rr.selectedLce = lce;
    };
    $scope.selected.rr.timeEnd = moment($scope.selected.rr.selectedLce.createdAt).add(1, 's').toISOString();
    saveRR($scope.selected.rr);
  };

  $scope.destroy = () => {
    const rr = new RecognizedReservation({ id: $scope.selected.rr.id });
    rr["delete"]().then((result) => {
      $scope.setForm();
      refetchEvents();
    });
  };

  $scope.split = () => {
    const rr = new RecognizedReservation({id: $scope.selected.rr.id});
    rr.split($scope.selected.rr.selectedLce.createdAt).then((rr) => {
      $scope.setForm('rr', rr);
      $scope.selected.rr = rr;
      $scope.selected.rr.selectedLce = rr.imagesDuring[0];
      $('#rw-fullcalendar').fullCalendar('refetchEvents');
    });
  };

  $scope.createRR = (lce) => {
    const rrToSave = new RecognizedReservation({
      timeStart: lce.createdAt,
      timeEnd: moment(lce.createdAt).add(5, 'm').toISOString(),
      serviceLocationLaneId: lce.serviceLocationLaneId,
    });
    rrToSave.save().then((rr) => {
      $scope.setForm('rr', rr);
      $scope.selected.rr = rr;
      $scope.selected.rr.selectedLce = rr.imagesDuring[0];
      $('#rw-fullcalendar').fullCalendar('refetchEvents');
    });
  };

  const refetchEvents = () => {
    $('#rw-fullcalendar').fullCalendar('refetchEvents');
  };

  const reservationsByTypeAndLane = () => {
    const allReservations = [...$scope.reservations, ...$scope.manualReservations];

    var reservationsByTypeAndLaneCount = {};
    $scope.resources.forEach(lane => {
      reservationsByTypeAndLaneCount[lane.id] = {
        'laneId': lane.id,
        'RecognizedReservation': 0,
        'Reservation': 0,
      }
    });

    // Special 'disabled' lanes
    reservationsByTypeAndLaneCount[DISABLED_LANE_ID] = {
      'laneId': DISABLED_LANE_ID,
      'RecognizedReservation': 0,
      'Reservation': 0,
    }

    allReservations.forEach(r => {
      let lane = r.resourceId;
      let type = r.type;

      if (!reservationsByTypeAndLaneCount[lane]) {
        reservationsByTypeAndLaneCount[DISABLED_LANE_ID][type] += 1;
      } else {
        reservationsByTypeAndLaneCount[lane][type] += 1;
      }
    });

    return reservationsByTypeAndLaneCount;
  };

  const mismatchingLanes = () => {
    let groupedReservations = reservationsByTypeAndLane();

    let mismatches = [];

    angular.forEach(groupedReservations, lane => {
      if (lane.Reservation != lane.RecognizedReservation) {
        mismatches.push(lane.laneId);
      }
    });

    return mismatches;
  };

  const appendWarningForLane = (row) => {
    let tooltipContainer = $compile(`
      <span style="float: right" class="warning-container">
        <i class="icon icon-sprite warning-icon"></i>
        <md-tooltip md-direction="top">
          <p style="text-align: center">Внимание!</p>
          <p style="text-align: justify">Количество автомобилей не совпадает с количеством заказов</p>
        </md-tooltip>
      </span>`)($scope);

    $(row).find('.fc-cell-content').append(tooltipContainer[0]);
  };

  const resetLaneWarnings = (view) => {
    view.el.find(`td.fc-resource-area tr[data-resource-id]`).each((_, row) => {
      row.style.backgroundColor = 'white';
    });

    $('.warning-container').remove();
  };
});
