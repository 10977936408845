angular.module('RocketWash').controller('WashSettingsKassaController', (
  $scope, $uibModal, $timeout, $filter, userSession, KassaSettingValue, rwApi, alertService,
) => {
  const DEVICE_TYPES = ['ModuleKassaDevice', 'KkmDevice']
  $scope.selectedTabIndex = 0
  $scope.progress = {
    deleteDevice: false
  }

  rwApi.sendRequest({
    method: 'GET',
    path: 'service_locations',
    params: {
      organization_id: userSession.organization.id,
      with_deleted: false
    }
  }).then(serviceLocations => {
    $scope.serviceLocations = serviceLocations
  })

  const reloadSettings = () => {
    if (!$scope.selectedDevice) {
      return $scope.settings = []
    }

    return KassaSettingValue.query({ kassaId: $scope.selectedDevice.id }).then((settings) => {
      $scope.settings = settings.filter(x => x.keyType === $scope.selectedDevice.type.slice(0, -6) + 'SettingKey'
        || x.keyType === 'KassaSettingKey').sort((a, b) => a.settingKeyId - b.settingKeyId)
    })
  }

  const reloadDevices = () => {
    return rwApi.sendRequest({
      method: 'GET',
      path: 'devices/all',
      params: {
        service_location_id: userSession.service_location_id,
      }
    }).then(devices => {
      $scope.kassaDevices = devices.filter(device => DEVICE_TYPES.includes(device.type))
      $scope.kassaDevices = $scope.kassaDevices.map(device => {
        device.initial_service_location_id = device.service_location_id
        return device
      })
      $scope.selectedDevice = $scope.kassaDevices[$scope.selectedTabIndex]
      $scope.selectedTabIndex = $scope.prevTab || 0
    })
  }

  $scope.updateDevice = (device) => {
    if (device.cashShift.id && device.service_location_id != device.initial_service_location_id) {
      device.service_location_id = device.initial_service_location_id
      return alertService.show({
        type: 'error',
        text: 'Открытая кассовая смена, закройте ее и попробуйте снова',
      })
    }
    rwApi.sendRequest({
      method: 'PUT',
      path: 'devices',
      data: {
        device
      }
    }).then(device => {
      const deviceIndex = _.findIndex($scope.kassaDevices, { id: data.device_id })
      $scope.kassaDevices[deviceIndex] = device
    })
  }

  $scope.$watch('selectedTabIndex', () => {
    // prevent current device assignment if the last tab is chosen
    if ($scope.kassaDevices && $scope.selectedTabIndex == $scope.kassaDevices.length) {
      return
    }

    if (_.isEmpty($scope.kassaDevices)) {
      reloadDevices().then(() => {
        reloadSettings()
      })
    } else {
      $scope.selectedDevice = $scope.kassaDevices[$scope.selectedTabIndex]
      reloadSettings()
    }
  })

  $scope.save = function (setting) {
    setting.kassaId = $scope.kassaDevices[$scope.selectedTabIndex].id

    setting.save({ kassaId: $scope.selectedDevice.id }).then(() => {
      userSession.reload()
      reloadSettings()
    })
  }

  $scope.closeShift = (device) => {
    if (confirm($filter('translate')('shifts.cash_shifts.closeShiftsWithoutZReport'))) {
      rwApi.sendRequest({
        method: 'POST',
        path: 'cash_shifts/close',
        data: {
          id: device.cashShift.id,
          service_location_id: device.service_location_id,
        }
      }).then((savedCs) => {
        device.cashShift = savedCs

        alertService.show({
          text: 'Кассовая смена успешно закрыта в программе',
        })
      })
    }
  }

  $scope.deleteDevice = () => {
    $scope.progress.deleteDevice = true
    rwApi.sendRequest({
      method: 'DELETE',
      path: 'devices',
      params: {
        id: $scope.selectedDevice.id,
        service_location_id: $scope.selectedDevice.service_location_id,
      }
    }).then(data => {
      $scope.progress.deleteDevice = false
      if (data.errors) {
        return alertService.show({
          text: data.errors[0].message.ru,
          type: 'error',
        })
      }

      reloadDevices().then(() => reloadSettings())
    })
  }

  $scope.$watch('selectedDevice', (device) => {
    if (device && !device.cashShift) {
      rwApi.sendRequest({
        method: 'GET',
        path: 'cash_shifts/current',
        params: {
          service_location_id: device.service_location_id,
          device_id: device.id
        }
      }).then(cashShift => {
        device.cashShift = cashShift
      })
    }
  })

  $scope.openFormPopup = function (device) {
    $scope.prevTab = $scope.selectedTabIndex
    $uibModal.open({
      templateUrl: 'pages/wash/settings/kassa/form.slim',
      controller: 'WashSettingsKassaFormController',
      resolve: {
        resolvedDevice: () => device,
        resolvedReadonly: false,
      },
    }).result.finally(() => {
      $timeout(reloadDevices().then(() => reloadSettings()), 1000)
    })
  }
})
