import {secretKey} from "@env";
angular.module('RocketWash').controller(
  'YaFuelReservationsController',
  ($scope, $http, $timeout, $translate,$interval, $location, alertService, userSession, Reservation) => {
    $scope.alertService = alertService;
    $scope.secretKey = secretKey.key;
    $scope.userSession = userSession
    $scope.orgParams = $location.$$url.split('?')[1];

    const intervalPromise = $interval(function() {
      if (userSession.getName() === 'employee_for_ya_fuel'){
        $scope.loadReservations();
      }
    }, 60000);

    $scope.$on('$destroy', function() {
      $interval.cancel(intervalPromise);
    });

    $scope.loadReservations = () => {
      $scope.pds = $scope.pageDataService.new({
        page: 'ya_fuel_reservations',
        filters: {
          attributes: [
            { name: 'time_start', type: 'datetime' },
            { name: 'rounded_price', type: 'number' },
          ],
          scopes: [
            'time_start', 'rounded_price', 'fully_paid'
          ],
        },
        pg: {
          url: '/ya_fuel_reservations/history',
          model: Reservation,
          query: {
            fromToField: 'time_start',
            predicate: '-time_start',
          },
        },
        definePaginateMethodsOn: $scope,
      });
    };

    $scope.paidLabel = reservation => (
      reservation.fullyPaid ? 'label label-success' : 'label label-important'
    );

    $scope.getTime = function() {
      const rounded_time = new Date(Math.floor(Date.now() / 300000) * 300000);

      const day = rounded_time.getDate().toString().padStart(2, '0');
      const month = (rounded_time.getMonth() + 1).toString().padStart(2, '0');
      const year = rounded_time.getFullYear();
      const hours = rounded_time.getHours().toString().padStart(2, '0');
      const minutes = rounded_time.getMinutes().toString().padStart(2, '0');

      return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    let logoutTimeout;
    function startLogoutTimer() {
      logoutTimeout = $timeout(logoutUser, 60000); // 10 minutes in milliseconds
    }
    function cancelLogoutTimer() {
      $timeout.cancel(logoutTimeout);
    }
    startLogoutTimer();
    function logoutUser() {
      $http.get('/session/sign_out').then(function() {
        $window.location.reload();
      });
    }

    angular.element(window.document).on('mousemove keydown', function() {
      cancelLogoutTimer();
      startLogoutTimer();
    });

    function encryptMessage(message, key) {
      let encryptedMessage = '';
      let keyIndex = 0;

      for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i) + key.charCodeAt(keyIndex);
        encryptedMessage += String.fromCharCode(charCode);

        keyIndex++;
        if (keyIndex === key.length) {
          keyIndex = 0;
        }
      }
      return btoa(encryptedMessage);
    };

    $scope.reservationOptions = {
      org_params: $scope.orgParams,
    };

    $scope.authOptions = {
      org_params: $scope.orgParams,
    };

    const runValidations = () => {
      let errors = [];

      if (!$scope.reservationOptions.reservation_sum) {
        errors.push('reservation_sum');
      }

      if (!$scope.reservationOptions.org_params) {
        errors.push('org_params');
      }

      errors.forEach((error) => {
        alertService.show({
          text: $translate.instant(`ya_fuel_reservation.errors.${error}`),
          type: 'error'
        });
      });

      return errors.length == 0;
    };

    const runAuthValidations = () => {
      let errors = [];

      if (!$scope.authOptions.pin) {
        errors.push('pin');
      }

      errors.forEach((error) => {
        alertService.show({
          text: $translate.instant(`ya_fuel_reservation.errors.${error}`),
          type: 'error'
        });
      });

      return errors.length == 0;
    };

    $scope.create = () => {
      const valid = runValidations();

      if (!valid) { return };
      let reservation_sum = $scope.reservationOptions.reservation_sum

      let message = `${reservation_sum}${$scope.getTime()}`;
      $scope.reservationOptions.token = encryptMessage(message, $scope.secretKey);

      $http.post('/ya_fuel_reservations', $scope.reservationOptions).then((response) => {
        alertService.show({
          text: $translate.instant('Заказ создан, сообщите клиенту сумму, которую нужно провести в приложении Яндекс Заправки!'),
          timeout: 60000
        });
        $scope.loadReservations();
      }, () => {
        alertService.show({
          text: $translate.instant('Ошибка создания заказа, свяжитесь со службой поддержки'),
          type: 'error',
          timeout: 60000
        });
      });
    };

    $scope.signInEmp = () => {
      const valid = runAuthValidations();

      if (!valid) { return };

      $http.post('/ya_fuel_reservations/sign_in_emp', $scope.authOptions).then((response) => {
        alertService.show({
          text: $translate.instant('Вы авторизованы!')
        });
        userSession.reload();
        $scope.loadReservations();
      }, () => {
        alertService.show({
          text: $translate.instant('Ошибка авторизации'),
          type: 'error',
          timeout: 60000
        });
      });
    };
    $scope.loadReservations();
  },
);
