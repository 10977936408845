angular.module('RocketWash').factory('rwFiltersService', (
  $filter, $rootScope, FilterGroup, $uibModal, $injector,
  pageDataService, PREDEFINED_FILTERS,
  PREDEFINED_FILTERS_YA_FO, PREDEFINED_FILTERS_FOR_INTEGRATIONS_RESERVATIONS,
  PREDEFINED_FILTERS_FOR_INTEGRATIONS_SL,
) => {
  const $storage = {};
  return {
    new(page) {
      // TODO: reindent
      // Please check this image for naming details: https://yadi.sk/i/qeK_minG3NuaKm
      const reloadRequired = !$storage[page];
      if (!$storage[page]) {
        $storage[page] = {};
      }
      const data = $storage[page];
      const userSession = $injector.get('userSession');
      const washWithYaFuel = userSession.service_location.yandex_fuel_available

      if (page === 'wash_financial_operations' && washWithYaFuel) {
        data.predefinedFilterGroups = PREDEFINED_FILTERS_YA_FO[page];
      } else if (page === 'admin_financial_operations') {
        data.predefinedFilterGroups = PREDEFINED_FILTERS_YA_FO[page];
      } else if (page === 'online_reservations') {
        data.predefinedFilterGroups = PREDEFINED_FILTERS_FOR_INTEGRATIONS_RESERVATIONS[page];
      } else if (page === 'service_locations') {
        data.predefinedFilterGroups = PREDEFINED_FILTERS_FOR_INTEGRATIONS_SL[page];
      }
      else {
        data.predefinedFilterGroups = PREDEFINED_FILTERS[page];
      }
      console.log(data.predefinedFilterGroups);

      const service = {
        $reset() {
          Object.assign(data, {
            filters: [],
            customFilters: [{}],
            filterGroups: [],
            historyFilterGroups: [],
            customFilterGroups: [],
            filterGroupsLoaded: false,
            historyFilterGroupsLoaded: false,
          });
        },

        $reload() {
          this.$reset();
          this.reloadFilterGroups();
          this.reloadHistoryFilterGroups();
        },

        getFilters: () => data.filters,
        getFilterGroups: () => data.filterGroups,
        getPredefinedFilterGroups: () => data.predefinedFilterGroups,
        getHistoryFilterGroups: () => data.historyFilterGroups,
        getCustomFilterGroups: () => data.customFilterGroups,
        areFilterGroupsLoaded: () => data.filterGroupsLoaded,

        // setPage: (page) => data.page = page,
        getPage: () => page,

        reloadFilterGroups: function() {
          FilterGroup.query({page: page}).then((filterGroups) => {
            data.filterGroups = filterGroups;
            data.filterGroupsLoaded = true;
            this.checkFilterGroups(data.filterGroups);
          });
        },

        reloadHistoryFilterGroups: function() {
          FilterGroup.query({page: page, history: true}).then((historyFilterGroups) => {
            data.historyFilterGroups = historyFilterGroups;
            data.historyFilterGroupsLoaded = true;
            this.checkFilterGroups(data.historyFilterGroups);
          });
        },

        updatePDSFilters: function () {
          pageDataService.assignSelectedFilters(page, data.filters);
          $rootScope.$emit('filters-updated');
        },

        areFiltersEqual: function(filter1, filter2) {
          return (filter1.id && filter1.id == filter2.id) ||
            (filter1.attr ==     filter2.attr &&
              filter1.condition == filter2.condition &&
              filter1.value ==     filter2.value);
        },

        checkFilterGroups: function(filterGroups) {
          _.each(filterGroups, (fg) => {
            fg.checked = _.every(fg.filters, (filter) => {
              return _.find(data.filters, (existingFilter) => {
                return this.areFiltersEqual(existingFilter, filter);
              });
            })
          });
        },

        uniqueFilters: function(filters) {
          return _.uniqBy(filters, function(filter) {
            return [filter.attr, filter.condition, filter.value].join('|');
          });
        },

        destroyFilterGroup: function(filterGroup) {
          if(filterGroup.checked) {
            this.toggleFilterGroup(filterGroup);
          };

          if (filterGroup.id) { // Filter group from DB
            filterGroup.delete().then(() => {
              this.reloadFilterGroups();
              this.reloadHistoryFilterGroups();
            });
          } else { // Custom filter group which was not saved
            data.customFilterGroups = _.without(data.customFilterGroups, filterGroup);
          };
        },

        toggleFilterGroup: function(filterGroup) {
          filterGroup.checked = !filterGroup.checked;

          if (filterGroup.checked) {
            _.each(filterGroup.filters, function(filter) {
              filter.filterGroup = filterGroup;
              data.filters.push(filter);
            });
          } else {
            data.filters = _.reject(data.filters, (filter) => {
              return _.some(filterGroup.filters, (fgFilter) => {
                return service.areFiltersEqual(filter, fgFilter);
              });
            });
          }

          data.filters = this.uniqueFilters(data.filters);
          this.saveHistorySearchGroup();
          this.checkFilterGroups(data.filterGroups);
          this.checkFilterGroups(data.historyFilterGroups);
          this.checkFilterGroups(data.customFilterGroups);
          this.updatePDSFilters();
        },

        saveHistorySearchGroup: function() {
          let filterGroup = new FilterGroup({
            page: page,
            history: true,
          });

          filterGroup.filters = this.prepareFiltersForSave(data.filters);
          filterGroup.name = this.defaultFilterGroupName(filterGroup);

          filterGroup.save();
          this.reloadHistoryFilterGroups();
        },

        addCustomFilterGroup: function(filterGroup) {
          data.customFilterGroups.push(filterGroup);
          this.toggleFilterGroup(filterGroup);
        },

        removeFilter: function(filter) {
          data.filters = _.without(data.filters, filter);
          this.checkFilterGroups(data.filterGroups);
          this.checkFilterGroups(data.historyFilterGroups);
          this.checkFilterGroups(data.customFilterGroups);

          this.updatePDSFilters();
        },

        saveFilterGroup: function() {
          let filterGroup = new FilterGroup({page: page});
          filterGroup.filters = this.prepareFiltersForSave(data.filters);

          $uibModal.open({
            templateUrl: 'directives/rw-filters/rw-save-filter-group-modal.slim',
            controller: 'RwSaveFilterGroupModalController',
            resolve: {
              filterGroup: () => { return filterGroup; },
            }
          }).result.then((result) => {
            data.customFilters = [{}];
            this.reloadFilterGroups();
            console.log(result);
          });
        },

        prepareSearchResults: function(scopes, searchText) {
          return _.map(scopes, (scopeName) => {
            return {
              scope: scopeName,
              scopeName: $filter('translateFilterScope')(scopeName, page),
              searchText: searchText,
              display: [searchText, $filter('translate')('filters.scope_in'), $filter('translateFilterScope')(scopeName, page)].join(' '),
            };
          });
        },

        addCustomSearchFilter: function(selectedItem) {
          console.log($storage);
          if (!selectedItem || !selectedItem.searchText || !selectedItem.scope) {
            return;
          };

          let filterGroup = new FilterGroup();
          filterGroup.filters = [{
            attr: selectedItem.scope,
            condition: 'includes',
            value: selectedItem.searchText,
          }]
          filterGroup.name = this.defaultFilterGroupName(filterGroup);

          this.addCustomFilterGroup(filterGroup);
          this.updatePDSFilters();
        },

        defaultFilterGroupName: function(filterGroup) {
          return _.map(filterGroup.filters, (filter) => {
            let attrName = $filter('translateFilterAttribute')(filter.attr, page);
            let conditionName = $filter('translate')('filters.conditions.' + filter.condition);

            let value = this.formatValueForFilter(filter);
            return [attrName, conditionName, value].join(' ');
          }).join("\n");
        },

        formatValue: function(value) {
          if (
            value instanceof Date ||
            moment.isMoment(value) ||
            (moment(value).isValid() && moment(value) === moment(value).toISOString())
          ) {
            return $filter('formatTime')(value, 'compact');
          }
          return value;
        },

        formatValueForFilter: function (filter) {
          if (filter.condition === 'date_range') {
            return $filter('formatDateRange')(filter.value);
          }
          return this.formatValue(filter.value);
        },

        prepareFiltersForSave: function(filters) {
          return angular.copy(filters).map((filter) => {
            return {
              attr: filter.attr,
              condition: filter.condition,
              value: filter.value,
            };
          });
        },
      };

      if (reloadRequired) {
        service.$reload();
      }

      return service;
      // TODO: reindent
    },
  };
});
