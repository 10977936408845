angular.module('RocketWash').directive('rwPhoneConfirmation', (
  alertService, rwApi
) => {
  const STAGES = {
    phone_not_confirmed: 'phone_not_confirmed',
    enter_code: 'enter_code',
    phone_confirmed: 'phone_confirmed',
  };

  return {
    restrict: 'E',
    scope: {
      user: '=',
    },
    templateUrl: 'directives/rw-phone-confirmation/index.slim',
    link(scope, _element, _attrs) {
      scope.data = { confirmationCode: null };
      scope.STAGES = STAGES;
      scope.resendCode = false;

      scope.$watch('[user.last_phone_confirmed_at, user.lastPhoneConfirmedAt]', () => {
        if (scope.user && (scope.user.last_phone_confirmed_at || scope.user.lastPhoneConfirmedAt)) {
          scope.stage = STAGES.phone_confirmed;
        } else {
          scope.stage = STAGES.phone_not_confirmed;
        }
      }, true);

      scope.allowConfirmation = () => {
        const make_id = scope.$parent.$parent.$parent.$parent.rf.carContractor.car.car_make_id;
        const model_id = scope.$parent.$parent.$parent.$parent.rf.carContractor.car.car_model_id;
        const user_phone = scope.user.phone;

        return make_id && model_id && user_phone;
      }

      const addContractorBeforeConfirmation = () => {
        const user = scope.user

        return rwApi.sendRequest({
          method: 'POST',
          path: 'reservation_form/customers',
          data: {
            user
          }
        }).catch(() => {
          scope.back();
        });
      }

      const sendPhoneConfirmationRequest = () => {
        scope.stage = STAGES.enter_code;
        rwApi.sendRequest({
          method: 'POST',
          path: 'reservation_form/customers/send_phone_confirmation',
          data: {
            id: scope.user.id,
            phone: scope.user.phone,
            service_location_id: scope.$parent.$parent.$parent.$parent.rf.reservations.reservation.service_location_id,
          }
        }).catch(() => {
          scope.back();
        });
      }

      scope.allowResendCode = () => {
        setTimeout(() => {
          scope.resendCode = true;
        }, 1000 * 15)
      }

      scope.back = () => {
        scope.stage = STAGES.phone_not_confirmed;
        scope.resendCode = false;
      };

      scope.sendPhoneConfirmation = () => {
        if (!scope.user.id) {
          addContractorBeforeConfirmation().then( (data) => {
            scope.user.id = data.id;

            sendPhoneConfirmationRequest();
          })
        } else {
          sendPhoneConfirmationRequest();
        }
      };

      scope.confirmPhone = () => {
        scope.confirmPhoneDisabled = true;
        rwApi.sendRequest({
          method: 'POST',
          path: 'reservation_form/customers/confirm_phone',
          data: {
            id: scope.user.id,
            code: scope.data.confirmationCode,
          }
        }).then((result) => {
          if (result.confirmed) {
            scope.user.last_phone_confirmed_at = result.last_phone_confirmed_at;
            scope.user.lastPhoneConfirmedAt = result.last_phone_confirmed_at;
            scope.stage = STAGES.phone_confirmed;
          } else {
            alertService.show({
              text: 'Введен не верный код подтверждения!',
              type: 'error',
            });
          }
        }).finally(() => {
          scope.confirmPhoneDisabled = false;
        });
      };
    },
  };
});
